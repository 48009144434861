.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: inherit; /* This will use the text color from your theme */
}

.privacy-policy h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  font-size: 1.8em;
  margin-top: 30px;
  margin-bottom: 15px;
}

.privacy-policy p, .privacy-policy li {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 10px;
}

.privacy-policy ul {
  margin-left: 20px;
  margin-bottom: 20px;
}

/* Add this to ensure the page takes up the full height */
.privacy-policy {
  min-height: 100vh;
}