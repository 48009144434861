.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: inherit;
}

.terms-of-service h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.terms-of-service h2 {
  font-size: 1.8em;
  margin-top: 30px;
  margin-bottom: 15px;
}

.terms-of-service p, .terms-of-service li {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 10px;
}

.terms-of-service ul {
  margin-left: 20px;
  margin-bottom: 20px;
}

.terms-of-service {
  min-height: 100vh;
}